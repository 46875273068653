import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Section from './section'

const useClasses = makeStyles(({ breakpoints }) => ({
  root: {
    height: `calc(100vh - 75px)`,
    width: '100%',
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 2,
  },
  imageContainer: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  text: {
    width: '100%',
    [breakpoints.up('md')]: { width: '33%' }
  }
}))

const Hero = ({ title, subtitle, image, imageAlt, ...rest }) => {
  const _classes = useClasses()

  return (
    <Box {...rest} className={_classes.root}>
      {/* <div className={_classes.imageContainer}>
        <Img className={_classes.image} fluid={image} alt={imageAlt} />
      </div> */}
      <Box
        display='flex'
        flexDirection='column'
        className={_classes.content}
        alignItems='flex-start'
        justifyContent='center'
      >
        <Section>
          <Typography variant='subtitle2' component='p'>
            {subtitle}
          </Typography>
          <Typography variant='h1' className={_classes.text}>
            {title}
          </Typography>
        </Section>
      </Box>
    </Box>
  )
}

export default Hero
