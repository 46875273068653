import React, { forwardRef } from 'react'
import Img from 'gatsby-image'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useClasses = makeStyles(({ palette }) => ({
  root: {
    display: 'block',
    width: '100%',
    transition: 'opacity 200ms ease-in',
    '&:hover': {
      opacity: '0.8'
    }
  },
  img: {
    transition: 'all 500ms ease-in'
  },
  focusVisible: {
    outline: `5px solid ${palette.primary.main}`
}
}))

const ImageButton = forwardRef(({ className, fluid, alt, src, ...rest }, ref) => {
  const _classes = useClasses()

  return (
    <ButtonBase
      {...rest}
      classes={{
        root: clsx(_classes.root, className),
        focusVisible: _classes.focusVisible
      }}
      ref={ref}
    >
      {fluid
        ? <Img className={_classes.img} fluid={fluid} alt={alt} />
        : <img className={_classes.img} src={src} alt={alt} />
      }
    </ButtonBase>
  )
})

export default ImageButton
