import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useBreakpoint = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isSmallDesktop = useMediaQuery(theme.breakpoints.only('md'))
  const isRegularDesktop = useMediaQuery(theme.breakpoints.only('lg'))
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only('xl'))

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isRegularDesktop,
    isLargeDesktop,
    isDesktop: !(isMobile || isTablet)
  }
}

export default useBreakpoint
