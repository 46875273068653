import React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversalAccess } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import SocialIcons from "../components/common/socialIcons";
import Grid from "../components/common/grid";
import Posts from "../components/posts";
import Project from "../components/project";
import Hero from "../components/common/hero";
import Section from "../components/common/section";

const useClasses = makeStyles(({ breakpoints, spacing, palette }) => ({
  introduction: {
    display: "grid",
    [breakpoints.up("md")]: { gridTemplateColumns: "1.75fr 1fr" },
  },
  icon: {
    fontSize: "100em",
    height: "100%",
    transform: "translateX(-50%)",
    opacity: 0.1,
  },
  ctaContainer: {
    minHeight: `calc(((100vw - (${spacing(1)}px * 2)) / 16) * 9)`,
    backgroundImage: ({ bridge }) => `url(${bridge.childImageSharp.fluid.src})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [breakpoints.up("lg")]: {
      minHeight: 0,
    },
  },
  cta: {
    height: "100%",
    padding: spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "white",
    "& > *:not(:last-child)": {
      marginBottom: spacing(5),
    },
  },
}));

const Home = (props) => {
  const projects = props.data.projects.nodes;
  const bridge = props.data.bridge.nodes[0];
  const _classes = useClasses({ bridge });

  return (
    <>
      <Hero
        title="Creating Websites for an Accessible Future"
        subtitle="Pitch"
      />
      <Section
        className={_classes.introduction}
        color="primary"
        position="relative"
        overflow="hidden"
        paddingY={{ xs: 10, md: 25 }}
      >
        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
          <FontAwesomeIcon icon={faUniversalAccess} className={_classes.icon} />
        </Box>
        <Box marginBottom={{ xs: 3, md: 0 }}>
          <Typography variant="subtitle2" component="p">
            Introduction
          </Typography>
          <Typography variant="h2">Built Accessibility First</Typography>
        </Box>
        <Typography variant="body1">
          Hello! I'm Karl Castillo, a developer and designer from Vancouver
          wanting to create the web a more accessible place. With your help we
          can make this a reality.
        </Typography>
      </Section>

      <Section style={{ paddingBottom: 0 }}>
        <Typography variant="subtitle2" component="p">
          Experience
        </Typography>
        <Typography variant="h2">Highlights</Typography>

        <Grid component="ul" paddingTop={{ xs: 7, md: 10 }} paddingX={0}>
          {projects.map((project) => (
            <Project key={project.name} {...project} />
          ))}
          {projects.length % 2 !== 0 && (
            <Box className={_classes.ctaContainer}>
              <Box className={_classes.cta}>
                <Typography variant="h3" align="center">
                  [ Your Project Here ]
                </Typography>
                <Typography align="center">
                  Like what you've seen so far? Let's make something great
                  together!
                </Typography>
                <Typography align="center">Drop me a message.</Typography>
                <SocialIcons icons={["mail", "linkedin"]} />
              </Box>
            </Box>
          )}
        </Grid>
      </Section>

      <Posts />
    </>
  );
};

export const pageQuery = graphql`
  {
    bridge: allFile(filter: { name: { eq: "bridge" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    projects: allProjectsJson {
      nodes {
        name
        company
        url
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default Home;
