import React from 'react'
import MUILink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const useClasses = makeStyles(({ palette, typography, shape, spacing }) => ({
  '@keyframes caretBounce': {
    '0%': { transform: 'translateX(0) scale(1)' },
    '50%': { transform: 'translateX(3px) scale(1.5)' },
    '100%': { transform: 'translateX(0) scale(1)' }
  },
  root: {
    fontSize: '1.1rem',
    display: 'inline-block',
    color: palette.getContrastText(palette.primary.contrastText),
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightBold,
    borderRadius: shape.borderRadius,
    padding: spacing(1),
    background: palette.primary.main,
    '&:hover': {
      background: palette.primary.dark,
      textDecoration: 'none',
      '& > span > svg': { animation: '1s linear infinite running $caretBounce'  }
    },
    '&:active > span': {
      filter: 'none'
    }
  },
  label: {
    filter: 'drop-shadow(1px 1px 1px black)'
  },
  focusVisible: {
      outline: `5px solid ${palette.primary.main}`,
      backgroundColor: palette.common.black,
      color: palette.common.white
  }
}))

const Link = ({ children, ...rest }) => {
  const _classes = useClasses()

  return (
    <MUILink
      {...rest}
      variant='body2'
      classes={{ root: _classes.root, focusVisible: _classes.focusVisible }}
    >
      <span className={_classes.label}>
        {children} <FontAwesomeIcon icon={faAngleRight} size='lg' />
      </span>
    </MUILink>
  )
}

export default Link
