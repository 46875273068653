import React, { useState } from "react";
import Img from "gatsby-image";
import Box from "@material-ui/core/Box";
import MUIChip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import useBreakpoint from "../hooks/useBreakpoint";
import IconButton from "./common/iconButton";
import ImageButton from "./common/imageButton";
import Link from "./common/link";

const Chip = withStyles(
  ({ spacing }) => ({
    root: {
      marginRight: spacing(1),
    },
  }),
  { withTheme: true }
)(MUIChip);

const useClasses = makeStyles(({ spacing, palette }) => ({
  content: {
    padding: "0 !important",
  },
  description: {
    padding: spacing(2),
    borderTop: `1px solid ${palette.grey[300]}`,
    "& > *:not(:last-child)": {
      marginBottom: spacing(2),
    },
  },
  closeButton: {
    position: "absolute",
    top: spacing(2),
    right: spacing(2),
    zIndex: 2,
  },
}));

const Project = ({ name, url, company, image, tags, ...rest }) => {
  const _classes = useClasses();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isMobile } = useBreakpoint();

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <>
      <Box {...rest}>
        <figure>
          <ImageButton
            display="block"
            onClick={openDialog}
            fluid={image.childImageSharp.fluid}
            aria-label={`View information about - ${name}`}
            alt={`${name} Homepage Screenshot`}
          />
          <figcaption>
            <Typography align="center" variant="caption">
              {name}
            </Typography>
          </figcaption>
        </figure>
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        onBackdropClick={closeDialog}
        handleClose={closeDialog}
        fullScreen={isMobile}
        fullWidth
        maxWidth="md"
      >
        <DialogContent classes={{ root: _classes.content }}>
          <IconButton
            className={_classes.closeButton}
            onClick={closeDialog}
            icon={faTimes}
            color="primary"
            size="2x"
            enableShadow
          />
          <Img
            fluid={image.childImageSharp.fluid}
            alt={`${name} Homepage Screenshot`}
          />
          <div className={_classes.description}>
            <Typography variant="h2">{name}</Typography>
            <Typography>{company}</Typography>
            <Box display="flex" flexWrap="wrap">
              {tags.map((tag) => (
                <Box marginBottom={1}>
                  <Chip label={tag} size="small" />
                </Box>
              ))}
            </Box>
            <Link href={url} target="_blank">
              Visit {name}
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Project;
