import React, { useEffect, useState } from 'react'
import range from 'lodash/range'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import Grid from './common/grid'
import Link from './common/link'
import Section from '../components/common/section'

const MAX_POSTS = 6

const useClasses = makeStyles(({ breakpoints, spacing }) => ({
  skeleton: { width: '100%', minHeight: '450px' },
  icon: { marginRight: spacing(1) }
}))

const Posts = () => {
  const _classes = useClasses()
  const [loading, setLoading] = useState(true)
  const [posts, setPosts] = useState([])
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    fetch(`https://dev.to/api/articles?username=koralarts&per_page=${MAX_POSTS}`)
      .then(res => res.json())
      .then(data => {
        setPosts(data)
        setLoading(false)
      })
      .catch(() => setHasError(true))
  }, [])

  return !hasError && (
    <Section>
      <Typography variant='subtitle2' component='p'>
        Community
      </Typography>
      <Typography variant='h2'>
        Posts
      </Typography>

      <Grid paddingY={{ xs: 7, md: 10 }}>
        {loading ? (
          range(MAX_POSTS).map(i =>
            <Skeleton
              key={`skeleton-${i}`}
              variant='rect'
              className={_classes.skeleton}
            />
          )
        ) : (
          posts.map(post =>
            <Box key={post.title}>
              <Typography variant='h3'>{post.title}</Typography>
              <Box marginY={3}>
                <Typography variant='body2' component='time'>
                  {moment(post.published_at).format('MMMM DD, YYYY')}
                </Typography>
                <Typography variant='body1'>{post.description}</Typography>
              </Box>
              <Link
                  href={post.url}
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label={`Read Article: ${post.title}`}
              >
                Read article
              </Link>
            </Box>
          )
        )}
      </Grid>
      <Link
        href='https://dev.to/koralarts'
        rel='noopener noreferrer'
        target='_blank'
        aria-label="View more articles in Dev.to"
      >
        View More Articles
      </Link>
    </Section>
  )
}

export default Posts
