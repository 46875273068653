import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

const useClasses = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'grid',
    gridColumnGap: spacing(1),
    gridRowGap: spacing(4),
    gridTemplateColumns: '1fr',
    [breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))'
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(33vw, 1fr))'
    }
  }
}))

const Grid = ({ children, className, ...rest }) => {
  const _classes = useClasses()

  return (
    <Box {...rest} className={clsx(_classes.root, className)}>
      {children}
    </Box>
  )
}

export default Grid
